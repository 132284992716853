@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap");

p,
a {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: "Roboto Condensed", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

/* Parallax container */
.parallax {
  height: 500px;

  background-attachment: fixed; /* Parallax için bu önemli */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* İçerik için */
.content {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  font-size: 2rem;
}

/* BLOG-CARD */
.blog-card:hover {
  opacity: 0.85;
}
.blog-card p {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Maksimum 3 satır */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blogs-card p {
  display: -webkit-box;
  -webkit-line-clamp: 10; /* Maksimum 3 satır */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}